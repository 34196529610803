import React, { useState, memo } from 'react'

const OptionImage = ({ url, image, incomingKey, handleSelectImage }) => {
  const [hovered, setHovered] = useState(false)

  return (
    <div
      className={`w-full flex relative ${incomingKey % 2 === 1 ? 'items-end justify-end' : 'items-start justify-start'} cursor-pointer`}
      onClick={() => handleSelectImage(url, image)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <img
        src={image}
        width={300}
        className="cursor-pointer transform hover:scale-105 transition duration-300 ease-in-out"
      />
      {hovered && (
        <div
          className={`absolute z-10 top-0 left-0 right-0 bottom-0 bg-black bg-opacity-40 transition-opacity duration-300 flex items-center justify-center`}
        >
          <div className="flex items-center justify-center py-2 px-6 bg-primary rounded-button">
            <span className="text-white z-20 opacity-100">Select</span>
          </div>
        </div>
      )}
    </div>
  )
}

export default memo(OptionImage)

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react'
import Button from './Button/Button'
import React from 'react'

const CancelModal = ({ title, label, isOpen, onConfirm, onClose }) => {
  const [loading, setLoading] = React.useState(false)

  const handleConfirm = async () => {
    setLoading(true)
    await onConfirm()
    setLoading(false)
  }

  return (
    <Modal isOpen={isOpen} isCentered onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader></ModalHeader>
        <ModalCloseButton />
        <ModalBody className="px-6 text-md">
          <div className="font-bold pb-1">{title}</div>
          <div className="font-bold text-primary">{label}</div>
        </ModalBody>
        <ModalFooter className="!pl-0 !pr-4">
          <div className="flex w-full justify-between">
            <Button
              type={'text'}
              size="small"
              text={'Back'}
              className="text-black"
              disabled={loading}
              onClick={onClose}
            />
            <Button
              size="small"
              text={'Yes'}
              className="bg-red-600 text-white"
              onClick={handleConfirm}
              disabled={loading}
            />
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default CancelModal

import React, { memo, useEffect, useMemo, useState } from 'react'
import Base from '../base'
import ClipLoader from 'react-spinners/ClipLoader'
import * as amplitude from '@amplitude/analytics-browser'
import { useObject } from 'react-firebase-hooks/database'
import { ref, database, auth } from '../../firebase'
import { useNavigate, useParams } from 'react-router-dom'
import CanvaModal from '../../components/EventDetails/CanvaModal/CanvaModal'
import EventCarouselView from '../../components/EventCarouselView'
import EventDetailsView from '../../components/EventDetailsView'
import { Helmet } from 'react-helmet'
import RequestTab from './Tab/RequestTab/RequestTab'
import MusicTab from './Tab/MusicTab/MusicTab'
import { useAuthState } from 'react-firebase-hooks/auth'
import EventDetailsViewCreatedEvent from '../../components/EventDetailsView/EventDetailsViewCreatedEvent/EventDetailsViewCreatedEvent'
import { getSettingsData, setSettingsData } from '../../services/database'
import { eventTimePassed } from '../../helpers'
import Button from '../../components/Button/Button'
import { update } from 'firebase/database'
import EventDetailsViewInviteModal from '../../components/EventDetailsView/EventDetailsViewInviteModal/EventDetailsViewInviteModal'
import { useToast } from '@chakra-ui/react'
import { getTokens } from '../../services/helpers'
import { createEventPlaylist } from '../../services/Circle'

const EventDetails = () => {
  const { circleCode } = useParams()
  const navigate = useNavigate()
  const toast = useToast()
  const [user, loading] = useAuthState(auth)
  const [toggleTab, setToggleTab] = useState(0)
  const [canvaModal, setCanvaModal] = useState(false)
  const [firstTimeModal, setFirstTimeModal] = useState(false)
  const [isLoadingPlaylist, setLoadingPlaylist] = useState(false)

  const [inviteModal, setInviteModal] = useState(false)

  const [eventSnap, eventLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/info`)
  )
  const [guestsSnap, guestsLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/guests`)
  )
  const [playlistSnap, playlistLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/playlist`)
  )
  const [itemsSnap, itemsLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/items`)
  )
  const [squareSnap, squareLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/squares`)
  )

  const squareInfo = squareSnap?.val()
  const playlist = playlistSnap?.val()
  const itemsInfo = itemsSnap?.val()
  const guestsInfo = useMemo(() => guestsSnap?.val() || {}, [guestsSnap])
  const eventInfo = eventSnap?.val()
  const isTimePassed = !!eventInfo?.isPremium && eventTimePassed(eventInfo)

  useEffect(() => {
    if (!user && !loading) {
      navigate(`/login?redirect_url=${window.location.pathname}`, {
        replace: true
      })
      return
    }
    if (!eventLoading && !loading) {
      const isHost = eventInfo?.hostID === user?.uid
      if (!eventInfo || !isHost) {
        navigate(`/404?from=${window.location.pathname}`, { replace: true })
        return
      }
      // if (eventInfo.withCanva && !eventInfo.completed) {
      //   navigate(`/event/update/${circleCode}/info`, { replace: true })
      //   return
      // }
    }
  }, [eventLoading, loading, user, eventInfo, navigate, circleCode])

  const handleCreatePlaylist = async () => {
    console.log('handleCreatePlaylist')
    setLoadingPlaylist(true)
    try {
      if (!eventInfo.playlistID) {
        const token = await getTokens('guest', null)
        if (!token.accessToken) return

        amplitude.track('Music Playlist added', {
          circleCode,
          status: 'success'
        })

        await createEventPlaylist({
          token,
          settingsToken: token,
          option: 'guest',
          provider: 'spotify',
          circleCode,
          name: eventInfo.name,
          type: eventInfo.type
        })
      }

      await update(ref(database, `circles/${circleCode}/info`), {
        musicCollab: true
      })
      setToggleTab(1)
    } catch (err) {
      console.log('fail', err)
      toast({
        status: 'error',
        title: err.message || 'Error',
        position: 'top'
      })
      if (!eventInfo.playlistID) {
        amplitude.track('Music Playlist added', {
          circleCode,
          status: 'failure'
        })
      }
    } finally {
      setLoadingPlaylist(false)
    }
  }

  const handleCreateItemList = async () => {
    await update(ref(database, `circles/${circleCode}/info`), {
      extraCollab: true
    })
    setToggleTab(0)
  }

  useEffect(() => {
    if (!itemsLoading && !eventLoading) {
      if (
        !!eventInfo?.musicCollab &&
        Object.keys(itemsInfo || {})?.length === 0
      ) {
        setToggleTab(1)
      }
    }
  }, [eventInfo?.musicCollab, itemsInfo, itemsLoading, eventLoading])

  useEffect(() => {
    if (!!eventInfo && !eventLoading) {
      const settingsData = getSettingsData() ?? {}
      if (!settingsData.firstTimeEvent) {
        setFirstTimeModal(true)
        setSettingsData({ ...settingsData, firstTimeEvent: true })
      }
    }
  }, [eventInfo, eventLoading])

  if (
    loading ||
    eventLoading ||
    guestsLoading ||
    playlistLoading ||
    itemsLoading ||
    squareLoading
  ) {
    return (
      <Base
        hideBg={true}
        bottomBar={false}
        allowFullHeight={true}
        verifiedOnly={true}
        blackLogo
        headerBackgroundColor={'transparent'}
        menuColor={'#000'}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>Coteri - Online invitation details</title>
          <meta
            name="description"
            content="See and edit the details for your invitation. Track responses. See the music playlist. Copy the invite link. Anyone with the invite link can add songs to the music playlist."
          />
        </Helmet>
        <div className="flex flex-1 items-center justify-center">
          <ClipLoader color="#5B4ABC" />
        </div>
      </Base>
    )
  }

  console.log('EventDetails: ', isTimePassed, eventInfo)

  return (
    <React.Fragment>
      <EventDetailsViewInviteModal
        isOpen={inviteModal}
        onClose={() => setInviteModal(false)}
        eventInfo={eventInfo}
        circleCode={circleCode}
      />

      <Base
        hideBg={true}
        bottomBar={false}
        allowFullHeight={true}
        verifiedOnly={true}
        blackLogo
        headerBackgroundColor={'transparent'}
        menuColor={'#000'}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>{`Coteri${!!eventInfo?.name ? ` - ${eventInfo.name}` : ''}`}</title>
          <meta
            name="description"
            content="See and edit the details for your invitation. Track responses. See the music playlist. Copy the invite link. Anyone with the invite link can add songs to the music playlist."
          />
        </Helmet>
        {eventLoading ? (
          <div className="flex flex-1 items-center justify-center">
            <ClipLoader color="#5B4ABC" />
          </div>
        ) : (
          <div className="flex flex-1 items-center h-full flex-col mb-[50px] relative">
            {!eventInfo.date ? (
              <div
                className="w-full p-4 mb-4 text-md text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                role="alert"
              >
                <div className="font-bold">Inactive Event!</div>
                <div>Add a date/time to activate this event.</div>
              </div>
            ) : isTimePassed ? (
              <div
                className="w-full p-4 mb-4 text-md text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                role="alert"
              >
                <div className="font-bold">Event Closed</div>
                <div>
                  This event has ended. You can no longer edit the event
                  details.
                </div>
              </div>
            ) : !!eventInfo?.isPremium ? (
              <div className="px-4 mb-6 py-3 w-full flex flex-col rounded-md items-center justify-center from-black to-[#727272] text-white bg-gradient-to-r">
                <div className="text-md font-bold">Platinum Event</div>
                <div className="text-sm font-semibold">
                  This event has full access to all features.
                </div>
              </div>
            ) : null}
            <div className="w-full flex gap-5 flex-col sm:flex-row">
              <div className="order-1 sm:w-[42%] w-[100%] relative">
                <EventCarouselView eventInfo={eventInfo} />
              </div>
              <div className="order-2 flex-1">
                <EventDetailsView
                  circleCode={circleCode}
                  eventInfo={eventInfo}
                  guestsInfo={guestsInfo}
                />

                {isTimePassed && !eventInfo.noteForGuests
                  ? null
                  : !eventInfo.noteForGuests && (
                      <div className="mt-[12px] w-full">
                        <div className="flex flex-row items-center justify-start">
                          <div className="bg-off-white p-3 w-full flex flex-col justify-center rounded-md">
                            <span
                              className="ml-[8px] text-primary font-semibold hover:underline cursor-pointer"
                              onClick={() =>
                                navigate(`/event/${circleCode}/edit`)
                              }
                            >
                              + Add event description, or a note for your guests
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
              </div>
            </div>

            {isTimePassed && !eventInfo.noteForGuests
              ? null
              : eventInfo.noteForGuests && (
                  <div className="w-full mt-4">
                    <div className="flex items-center justify-between">
                      <span>Additional event information</span>
                    </div>
                    <div className="w-full h-[2px] bg-secondary mb-2" />
                    <button
                      className="bg-off-white p-3 flex flex-col justify-center rounded-md cursor-pointer w-full"
                      disabled={isTimePassed}
                      onClick={() => navigate(`/event/${circleCode}/edit`)}
                    >
                      <span>{eventInfo.noteForGuests}</span>
                    </button>
                  </div>
                )}

            <div className="mt-4 w-full flex justify-center">
              {!isTimePassed ? (
                <div className="flex items-center justify-between max-w-[350px] w-full">
                  <Button
                    text="Send invites"
                    size={'medium'}
                    onClick={() => setInviteModal(true)}
                    className={'w-full'}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className="w-full mt-[10px]">
              <div>
                {isTimePassed & !eventInfo?.extraCollab &&
                !eventInfo?.musicCollab ? null : (
                  <div className="w-full flex items-center justify-center mt-10 sm:mb-0 mb-2">
                    <div className="flex flex-1 bg-off-white h-[2px] mr-2" />
                    <span className="text-[13px] text-secondary">
                      Additional event features
                    </span>
                    <div className="flex flex-1 bg-off-white h-[2px] ml-2" />
                  </div>
                )}

                {/* <div
                className={`cursor-pointer relative flex flex-1 py-[10px] items-center justify-center rounded-md bg-off-white text-primary transition-colors duration-300 ease-in-out hover:bg-[#e6e6f1] mb-2`}
                onClick={() => {
                  if (squareInfo) {
                    navigate(`/event/${circleCode}/squares/summary`)
                    return
                  }
                  navigate(`/event/${circleCode}/squares/choose`)
                }}
              >
                <span>
                  + Super Bowl Squares
                </span>
              </div> */}

                {isTimePassed & !eventInfo?.extraCollab &&
                !eventInfo?.musicCollab ? null : (
                  <ul
                    className={`flex w-full mb-5 sm:gap-2 gap-2 flex-col mt-1 sm:flex-row`}
                  >
                    {isTimePassed & !eventInfo?.extraCollab ? null : (
                      <div
                        onClick={() =>
                          eventInfo?.extraCollab
                            ? setToggleTab(0)
                            : handleCreateItemList()
                        }
                        className={`cursor-pointer relative flex flex-1 py-[10px] items-center justify-center rounded-md ${toggleTab === 0 && eventInfo?.extraCollab ? 'bg-terciary text-white' : 'bg-off-white text-primary transition-colors duration-300 ease-in-out hover:bg-[#e6e6f1]'}`}
                      >
                        <span>
                          {eventInfo?.extraCollab
                            ? 'What to bring list'
                            : '+ Add “What to bring” list'}
                        </span>
                      </div>
                    )}

                    {isTimePassed & !eventInfo?.musicCollab ? null : (
                      <li
                        onClick={() =>
                          eventInfo?.musicCollab
                            ? setToggleTab(1)
                            : handleCreatePlaylist()
                        }
                        disabled={isLoadingPlaylist}
                        className={`cursor-pointer relative flex flex-1 py-[10px] items-center justify-center rounded-md ${toggleTab === 1 && eventInfo?.musicCollab ? 'bg-terciary text-white' : 'bg-off-white text-primary transition-colors duration-300 ease-in-out hover:bg-[#e6e6f1]'}`}
                      >
                        {eventInfo?.musicCollab
                          ? 'Music playlist'
                          : '+ Add Music playlist'}
                      </li>
                    )}
                  </ul>
                )}

                {eventInfo?.extraCollab && (
                  <RequestTab
                    circleInfo={eventInfo}
                    isToggled={toggleTab === 0}
                    itemsInfo={itemsInfo}
                    eventInfo={eventInfo}
                    guestsInfo={guestsInfo}
                    owner={true}
                  />
                )}

                {eventInfo?.musicCollab && (
                  <MusicTab
                    isToggled={toggleTab === 1}
                    circleInfo={eventInfo}
                    playlist={playlist}
                  />
                )}
              </div>
            </div>
          </div>
        )}

        {!!canvaModal ? (
          <CanvaModal
            circleCode={circleCode}
            isOpen={canvaModal}
            handleOnClose={() => setCanvaModal(false)}
            oldOcassion={eventInfo.ocassion}
          />
        ) : null}

        {!!firstTimeModal ? (
          <EventDetailsViewCreatedEvent
            isOpen={firstTimeModal}
            onClose={() => setFirstTimeModal(false)}
          />
        ) : null}
      </Base>
    </React.Fragment>
  )
}

export default memo(EventDetails)

import React, { useEffect, useState } from 'react'
import DottedLine from '../../DottedLine/DottedLine'
import Toggle from '../../Toggle/Toggle'
import Button from '../../Button/Button'
import { useWatch } from 'react-hook-form'
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react'

const NewEventCostModal = ({ hasOldCost, opened, onClose, form }) => {
  const [squareCost, setSquareCost] = useState(undefined)
  const [venmo, setVenmo] = useState(undefined)
  const [cashapp, setCashapp] = useState(undefined)
  const [paypal, setPaypal] = useState(undefined)
  const [zelle, setZelle] = useState(undefined)
  const [allowCash, setAllowCash] = useState(false)

  const hasCostError = !!squareCost && squareCost < 1
  const hasPaymentMethodError =
    squareCost > 0 && !venmo && !cashapp && !paypal && !zelle && !allowCash

  const [
    squareCostValue,
    venmoValue,
    cashappValue,
    paypalValue,
    zelleValue,
    allowCashValue
  ] = useWatch({
    name: ['costPerSquare', 'venmo', 'cashapp', 'paypal', 'zelle', 'allowCash'],
    control: form.control
  })

  const onConfirm = () => {
    form.setValue('costPerSquare', squareCost)
    form.setValue('venmo', venmo)
    form.setValue('cashapp', cashapp)
    form.setValue('paypal', paypal)
    form.setValue('zelle', zelle)
    form.setValue('allowCash', allowCash)

    onClose()
  }

  const onDelete = () => {
    form.setValue('costPerSquare', undefined)
    form.setValue('venmo', undefined)
    form.setValue('cashapp', undefined)
    form.setValue('paypal', undefined)
    form.setValue('zelle', undefined)
    form.setValue('allowCash', undefined)
    onClose()
  }

  useEffect(() => {
    setSquareCost(squareCostValue)
    setVenmo(venmoValue)
    setCashapp(cashappValue)
    setPaypal(paypalValue)
    setZelle(zelleValue)
    setAllowCash(allowCashValue)
  }, [
    allowCashValue,
    cashappValue,
    paypalValue,
    squareCostValue,
    venmoValue,
    zelleValue
  ])

  return (
    <Modal isOpen={opened} isCentered onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Cost per guest</ModalHeader>
        <ModalCloseButton />
        <ModalBody className="!p-0">
          {hasOldCost ? (
            <div className="flex justify-end px-6">
              <button
                className="text-red-500 underline text-[14px] cursor-pointer"
                onClick={onDelete}
              >
                delete
              </button>
            </div>
          ) : null}

          <div className="relative py-1">
            <div className="absolute right-0 top-0 left-6 bottom-0">
              <DottedLine color={'#80808033'} />
            </div>

            <div className="ml-6 mt-5 z-50">
              <div className="flex flex-row items-start mt-4">
                <div className="w-[3px] h-4 bg-primary" />
                <div className="flex flex-col ml-4">
                  <div className="flex-1 leading-4">
                    <span className="font-semibold pr-2">Payment methods</span>
                    <span className="text-sm text-red-600">
                      Required (min 1)
                    </span>
                  </div>
                  <div className="text-[12px]">
                    The way guests will pay you for event-related costs.
                  </div>
                </div>
              </div>

              <div className="flex flex-col gap-1 mt-3 ml-4">
                <div className="flex flex-row items-center mb-2">
                  <span className="text-primary  cursor-pointer w-[85px]">
                    Venmo
                  </span>
                  <div
                    className={`w-[190px] h-[35px] ml-2 rounded-md border-1 z-50 flex items-center justify-center relative border-primary bg-off-white`}
                  >
                    <span className=" text-primary ml-1">@</span>
                    <input
                      className={`w-full ml-2 outline-none h-full min-w-0 bg-transparent text-primary`}
                      value={venmo}
                      onChange={(e) => setVenmo(e.target.value)}
                    />
                  </div>
                </div>

                <div className="flex flex-row items-center mb-2">
                  <span className="text-primary  cursor-pointer w-[85px]">
                    Cash App
                  </span>
                  <div
                    className={`w-[190px] h-[35px] ml-2 rounded-md border-1 z-50 flex items-center justify-center relative border-primary bg-off-white`}
                  >
                    <span className=" text-primary ml-1">$</span>
                    <input
                      className={`w-full ml-2 outline-none h-full min-w-0 bg-transparent text-primary`}
                      value={cashapp}
                      onChange={(e) => setCashapp(e.target.value)}
                    />
                  </div>
                </div>

                <div className="flex flex-row items-center mb-2">
                  <span className="text-primary  cursor-pointer w-[85px]">
                    Paypal
                  </span>
                  <div
                    className={`w-[190px] h-[35px] ml-2 rounded-md border-1 z-50 flex items-center justify-center relative border-primary bg-off-white`}
                  >
                    <span className=" text-primary ml-1">@</span>
                    <input
                      className={`w-full ml-2 outline-none h-full min-w-0 bg-transparent text-primary`}
                      value={paypal}
                      onChange={(e) => setPaypal(e.target.value)}
                    />
                  </div>
                </div>

                <div className="flex flex-row items-center mb-2">
                  <span className="text-primary  cursor-pointer w-[85px]">
                    Zelle
                  </span>
                  <div
                    className={`w-[190px] h-[35px] ml-2 rounded-md border-1 z-50 flex items-center justify-center relative border-primary bg-off-white`}
                  >
                    <input
                      className={`w-full ml-2 outline-none h-full min-w-0 bg-transparent text-primary placeholder:text-[14px] placeholder:text-primary`}
                      placeholder="Email or phone number"
                      value={zelle}
                      onChange={(e) => setZelle(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <div className="flex flex-row items-center">
                    <span className="text-primary  cursor-pointer mr-2">
                      Allow cash payment?
                    </span>
                    <Toggle
                      textOn="Yes"
                      textOff="No"
                      value={allowCash}
                      setValue={setAllowCash}
                    />
                  </div>
                  <span className="text-[12px]">
                    Guest will have the option to pay in cash you upon arrival.
                  </span>
                </div>
              </div>

              <div className="flex flex-row items-start mt-4">
                <div className="w-[3px] h-4 bg-primary" />
                <div className="flex flex-col ml-4 gap-2">
                  <div className="font-semibold leading-4">Cost</div>

                  <div className="flex flex-row items-center mb-2">
                    <span className="text-primary  cursor-pointer w-[85px]">
                      Per guest
                    </span>
                    <div
                      className={`w-[190px] h-[35px] ml-2 rounded-md border-1 z-50 flex items-center justify-center relative border-primary bg-off-white`}
                    >
                      <span className=" text-primary ml-1">$</span>
                      <input
                        type="number"
                        className={`w-full ml-2 outline-none h-full min-w-0 bg-transparent text-primary`}
                        min={1}
                        placeholder="0"
                        value={squareCost}
                        onChange={(e) => setSquareCost(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {hasCostError ? (
            <div className="flex px-6 text-red-500 mt-4">
              Must be greater than $0
            </div>
          ) : hasPaymentMethodError ? (
            <div className="flex px-6 text-red-500 mt-4">
              Please add at least one payment method
            </div>
          ) : null}
        </ModalBody>
        <ModalFooter className="!pl-0 !pr-4">
          <div className="flex w-full justify-between">
            <Button text={'Back'} type={'text'} onClick={onClose} />
            <Button
              text={'Save'}
              onClick={onConfirm}
              disabled={hasCostError || hasPaymentMethodError}
            />
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default NewEventCostModal

import { useToast } from '@chakra-ui/react'
import React from 'react'
import { ref, remove } from 'firebase/database'
import { auth, database } from '../../../firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import CancelModal from '../../CancelModal'

const CancelEventModal = ({ eventInfo, opened, onClose, fetchCircles }) => {
  const toast = useToast()
  const [user] = useAuthState(auth)

  const handleDelete = async () => {
    try {
      if (!eventInfo?.key) {
        toast({
          title: 'Event not found',
          position: 'top',
          status: 'error',
          isClosable: true
        })
        return
      }

      await Promise.all([
        remove(ref(database, `circles/${eventInfo.key}`)),
        remove(ref(database, `circlesRef/${user?.uid}/${eventInfo.key}`))
      ])

      await fetchCircles()

      onClose()

      toast({
        title: 'Event canceled',
        position: 'top',
        status: 'success',
        isClosable: true
      })
    } catch (err) {
      console.log('CancelEventModal: ', err)
      toast({
        title: 'Error canceling event',
        position: 'top',
        status: 'error',
        isClosable: true
      })
    }
  }

  return (
    <CancelModal
      title="Are you sure you want to cancel this event?"
      label={eventInfo?.name}
      isOpen={opened}
      onConfirm={handleDelete}
      onClose={onClose}
    />
  )
}

export default CancelEventModal

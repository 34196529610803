import React, { useEffect, useState } from 'react'
import Base from '../base'
import { Container, Flex } from '@chakra-ui/react'
import Stepper from '../../components/Stepper/Stepper'
import SelectProviderStep from './Step1/SelectProviderStep'
import SelectSongsStep from './Step2/SelectSongsStep'
import { useObject } from 'react-firebase-hooks/database'
import { auth, database, ref } from '../../firebase'
import { getProviderToken } from '../../services/helpers'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Helmet } from 'react-helmet'
import ClipLoader from 'react-spinners/ClipLoader'
import { eventTimePassed } from '../../helpers'

const Playlist = () => {
  const { circleCode } = useParams()
  const [step, setStep] = useState(0)
  // const [checkProvider, setCheckProvider] = useState(null)
  const [option, setOption] = useState(null)
  const [provider, setProvider] = useState(null)
  const navigate = useNavigate()
  const [user, loading] = useAuthState(auth)

  const [eventSnap, eventLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/info`)
  )

  const eventInfo = eventSnap?.val()

  // useEffect(() => {
  //   if (!!checkProvider) {
  //     console.log('useEffect checkProvider', checkProvider)
  //     if (checkProvider === 'guest') {
  //       setValidating(false)
  //     } else {
  //       console.log('validateUserProvider', checkProvider)
  //       getProviderToken(checkProvider, `streaming-${circleCode}`).then(
  //         (token) => {
  //           if (!token.accessToken && !token.appleMusicToken) {
  //             return
  //           }
  //           setStep(1)
  //           setProvider(checkProvider)
  //           setValidating(false)
  //         }
  //       )
  //     }
  //   }
  // }, [checkProvider, circleCode])

  useEffect(() => {
    if (!eventLoading && !loading) {
      const isHost = eventInfo?.hostID === user?.uid
      const isTimePassed = !!eventInfo?.isPremium && eventTimePassed(eventInfo)
      if (!eventInfo || !isHost || isTimePassed) {
        navigate(`/404?from=${window.location.pathname}`, { replace: true })
        return
      }
      // if (eventInfo.withCanva && !eventInfo.completed) {
      //   navigate(`/event/update/${circleCode}/info`, { replace: true })
      //   return
      // }
    }
  }, [eventLoading, loading, user, eventInfo, navigate, circleCode])

  if (loading || eventLoading) {
    return (
      <Base hideBg={true} bottomBar={false} allowFullHeight={true}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Coteri - See the music playlist for the event</title>
          <meta
            name="description"
            content="Filter through your music and select songs for the event playlist. Songs you select will generate suggestions for your guests."
          />
        </Helmet>
        <div className="flex flex-1 flex-col items-center justify-center">
          <ClipLoader color="#5B4ABC" />
        </div>
      </Base>
    )
  }

  return (
    <Base hideBg={true} bottomBar={false} allowFullHeight={true}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Coteri - See the music playlist for the event</title>
        <meta
          name="description"
          content="Filter through your music and select songs for the event playlist. Songs you select will generate suggestions for your guests."
        />
      </Helmet>
      <div className="flex flex-1 flex-col md:items-stretch">
        {step === 0 ? (
          <SelectProviderStep
            circleCode={circleCode}
            circleInfo={eventInfo}
            setProvider={setProvider}
            setOption={setOption}
            setStep={setStep}
          />
        ) : null}

        {step === 1 && !!provider ? (
          <SelectSongsStep
            circleCode={circleCode}
            circleInfo={eventInfo}
            setStep={setStep}
            option={option}
            provider={provider}
          />
        ) : null}
      </div>
    </Base>
  )
}

export default Playlist

import React, { memo, useMemo, useState } from 'react'
import {
  CalendarIcon,
  ClockIcon,
  LocationIcon,
  PeopleOutlineIcon
} from '../assets/icons'
import moment from 'moment'
import { CostIcon } from '../assets/icons'
import { useNavigate } from 'react-router-dom'
import HostNameModal from './EventDetails/HostNameModal/HostNameModal'
import EditEvent from './EventDetails/EditEvent/EditEvent'
import { ref, update } from 'firebase/database'
import { database } from '../firebase'
import DisableInviteLinkModal from './EventDetails/DisableInviteLinkModal/DisableInviteLinkModal'
import EnableInviteLinkModal from './EventDetails/EnableInviteLinkModal/EnableInviteLinkModal'
import EventDetailsViewInviteModal from './EventDetailsView/EventDetailsViewInviteModal/EventDetailsViewInviteModal'
import { eventTimePassed } from '../helpers'

const EventDetailsView = ({ circleCode, eventInfo, guestsInfo }) => {
  const isTimePassed = !!eventInfo?.isPremium && eventTimePassed(eventInfo)

  const [editEventModal, setEditEventModal] = useState(false)
  const [hostNameModal, setHostNameModal] = useState(false)
  const [enableInviteLinkModal, setEnableInviteLinkModal] = useState(false)
  const [disableInviteLinkModal, setDisableInviteLinkModal] = useState(false)
  const [inviteModal, setInviteModal] = useState(false)
  const navigate = useNavigate()

  const timeOfEvent = useMemo(() => {
    if (!eventInfo || !eventInfo.date || !eventInfo.time || !eventInfo.timezone)
      return ''
    return moment(`${eventInfo.date}T${eventInfo.time}`).format(
      `hh:mm A [${eventInfo.timezone}]`
    )
  }, [eventInfo])

  const handleEnableInviteLinkModalConfirm = async () => {
    try {
      await update(ref(database, `circles/${circleCode}/info`), {
        allowGuests: true
      })

      setEnableInviteLinkModal(false)
    } catch (err) {
      console.log('EventDetailsView: ', err)
    }
  }

  const handleEnableInviteLinkModalClose = () => {
    setEnableInviteLinkModal(false)
  }

  const handleDisableInviteLinkModalClose = () => {
    setDisableInviteLinkModal(false)
  }

  const handleDisableInviteLinkModalConfirm = async () => {
    try {
      await update(ref(database, `circles/${circleCode}/info`), {
        allowGuests: false
      })

      setDisableInviteLinkModal(false)
    } catch (err) {
      console.log('EventDetailsView: ', err)
    }
  }

  const handleDeleteNote = () => {
    try {
      update(ref(database, `circles/${circleCode}/info`), {
        noteForGuests: ''
      })
    } catch (err) {
      console.log('EventDetailsView: ', err)
    }
  }

  if (!eventInfo) {
    return null
  }

  return (
    <div className="flex flex-col flex-1">
      <div>
        <div className="flex flex-row items-center justify-between mb-[15px]">
          <span className="mr-[35px] font-medium text-[24px]">
            {eventInfo.name}
          </span>
          <button
            className="text-primary cursor-pointer font-bold hover:underline min-w-[80px] disabled:text-gray disabled:cursor-default disabled:no-underline"
            disabled={!!eventInfo.date && !!eventInfo.time && isTimePassed}
            onClick={() => navigate(`/event/${circleCode}/edit`)}
          >
            Edit event
          </button>
        </div>
      </div>

      <div>
        <span className="mr-[35px] font-medium">
          Hosted by{' '}
          {eventInfo.hostName ? (
            eventInfo.hostName
          ) : (
            <span
              className=" text-primary cursor-pointer font-semibold hover:underline"
              onClick={() => setHostNameModal(true)}
            >
              Add host name
            </span>
          )}
        </span>

        <div className="bg-off-white p-3 flex flex-col justify-center mt-[12px] gap-3 rounded-md">
          <div className="flex flex-row items-center justify-start">
            <CalendarIcon />
            {eventInfo.date ? (
              <span className="ml-[8px]">
                {moment(eventInfo.date).format('MMMM DD, YYYY')}
              </span>
            ) : (
              <span
                className="ml-[8px] text-primary cursor-pointer font-semibold hover:underline"
                onClick={() => navigate(`/event/${circleCode}/edit`)}
              >
                Add date
              </span>
            )}
          </div>
          <div className="flex flex-row items-center justify-start">
            <ClockIcon />
            {eventInfo.time ? (
              <span className="ml-[8px]">{timeOfEvent}</span>
            ) : (
              <span
                className="ml-[8px] text-primary cursor-pointer font-semibold hover:underline"
                onClick={() => navigate(`/event/${circleCode}/edit`)}
              >
                Add time
              </span>
            )}
          </div>
        </div>

        {isTimePassed && !eventInfo.address?.label ? null : (
          <div className="bg-off-white p-3 flex flex-col justify-center mt-[12px] rounded-md">
            <div className="flex flex-row items-center justify-start">
              <LocationIcon fill="#5b4abc" pinColor="#422d8f" />
              {eventInfo.address?.label ? (
                <div className="flex flex-col">
                  <button
                    className="ml-[8px] text-start"
                    disabled={isTimePassed}
                    onClick={() => navigate(`/event/${circleCode}/edit`)}
                  >
                    {eventInfo.address?.label}
                    {eventInfo.address?.complementaryAddress
                      ? `, ${eventInfo.address?.complementaryAddress}`
                      : ''}
                  </button>
                  <button
                    className="ml-[8px] text-start"
                    disabled={isTimePassed}
                    onClick={() => navigate(`/event/${circleCode}/edit`)}
                  >
                    {eventInfo.address?.label2}
                  </button>
                </div>
              ) : (
                <button
                  className="ml-[8px] text-primary cursor-pointer font-semibold hover:underline"
                  onClick={() => navigate(`/event/${circleCode}/edit`)}
                >
                  Add event location
                </button>
              )}
            </div>
          </div>
        )}

        <div className="bg-off-white p-3 flex flex-col justify-center mt-[12px] rounded-md">
          <div className="flex flex-row items-center justify-start">
            <PeopleOutlineIcon fill="#5b4abc" />
            <div>
              <span className="ml-[8px]">
                {!!guestsInfo ? Object.values(guestsInfo).length : 0} RSVPs •{' '}
              </span>
              <span
                className="text-primary cursor-pointer font-semibold hover:underline"
                onClick={() => navigate(`/event/${circleCode}/rsvps`)}
              >
                View guests
              </span>
            </div>
          </div>
        </div>

        {isTimePassed && !eventInfo.costPerSquare ? null : (
          <div className="mt-[12px] w-full">
            <button
              className="bg-off-white p-3 w-full flex flex-col justify-center rounded-md cursor-pointer"
              onClick={() => navigate(`/event/${circleCode}/edit`)}
            >
              <div className="flex items-center">
                <CostIcon className="w-[24px] h-[24px]" fill={'#5B4ABC'} />
                <span
                  className={`ml-[8px] text-primary font-semibold ${!eventInfo.costPerSquare && 'hover:underline cursor-pointer'}`}
                >
                  {eventInfo.costPerSquare
                    ? `$${eventInfo.costPerSquare} per guest`
                    : '+ Cost per guest'}
                </span>
              </div>
              {!!eventInfo.costPerSquare ? (
                <span className="text-primary underline cursor-pointer">
                  Edit payment options
                </span>
              ) : null}
            </button>
          </div>
        )}

        {/* {isTimePassed && !eventInfo.noteForGuests ? null : (
          <div className="mt-[12px] w-full">
            <div className="flex flex-row items-center justify-start">
              {eventInfo.noteForGuests ? (
                <div className="w-full">
                  <div className="flex items-center justify-between">
                    <span>Additional event information</span>
                    {isTimePassed ? null : (
                      <button
                        className="text-red-500 text-[14px] cursor-pointer"
                        disabled={isTimePassed}
                        onClick={handleDeleteNote}
                      >
                        delete
                      </button>
                    )}
                  </div>
                  <div className="w-full h-[2px] bg-secondary mb-2" />
                  <button
                    className="bg-off-white p-3 flex flex-col justify-center rounded-md cursor-pointer"
                    disabled={isTimePassed}
                    onClick={() => navigate(`/event/${circleCode}/edit`)}
                  >
                    <span>{eventInfo.noteForGuests}</span>
                  </button>
                </div>
              ) : (
                <div className="bg-off-white p-3 w-full flex flex-col justify-center rounded-md">
                  <span
                    className="ml-[8px] text-primary font-semibold hover:underline cursor-pointer"
                    onClick={() => navigate(`/event/${circleCode}/edit`)}
                  >
                    + Add event description, or a note for your guests
                  </span>
                </div>
              )}
            </div>
          </div>
        )} */}
      </div>

      <div>
        {/* <div className="mt-4">
          <Button
            type="terciary"
            size="small"
            text={"Send text blast"}
            onClick={() => setTextBlastModal(true)}
          />
        </div> */}
      </div>

      <HostNameModal
        isOpen={hostNameModal}
        handleOnClose={() => setHostNameModal(false)}
        eventInfo={eventInfo}
      />

      <EventDetailsViewInviteModal
        isOpen={inviteModal}
        onClose={() => setInviteModal(false)}
        eventInfo={eventInfo}
        circleCode={circleCode}
      />

      <EditEvent
        isOpen={editEventModal}
        handleOnClose={() => setEditEventModal(false)}
        eventInfo={eventInfo}
        circleCode={circleCode}
      />

      <DisableInviteLinkModal
        onClose={handleDisableInviteLinkModalClose}
        isOpen={disableInviteLinkModal}
        onConfirm={handleDisableInviteLinkModalConfirm}
      />

      <EnableInviteLinkModal
        onClose={handleEnableInviteLinkModalClose}
        isOpen={enableInviteLinkModal}
        onConfirm={handleEnableInviteLinkModalConfirm}
      />
    </div>
  )
}

export default memo(EventDetailsView)

import React, { useMemo, useState } from 'react'
import { Flex, Image } from '@chakra-ui/react'
import { EditBorderIcon } from '../../../assets/icons'
import PhotoOrVideoModal from '../../EventDetails/PhotoOrVideoModal/PhotoOrVideoModal'
import { categories, DEFAULT_COVER } from '../../../utils/categories'

const NewEventImage = ({ form, view }) => {
  const [isLoading, setLoading] = useState(true)
  const [uploadDesignModal, setUploadDesignModal] = useState(false)

  const [url, eventImage] = form.watch(['url', 'eventImage'])

  const handleImageUrl = async (url, image) => {
    const response = await fetch(image)
    const blob = await response.blob()

    const parts = image.split('/')
    const fileName = parts[parts.length - 1]

    const file = new File([blob], fileName, { type: blob.type })

    form.setValue('eventImage', undefined)
    form.setValue('url', url)
    setUploadDesignModal(false)
  }

  const handleImageFile = (image) => {
    form.setValue('url', undefined)
    form.setValue('eventImage', image)
    setUploadDesignModal(false)
  }

  const eventImageSrc = useMemo(
    () =>
      !!eventImage && !!eventImage[0] && !!eventImage[0].name
        ? URL.createObjectURL(eventImage[0])
        : null,
    [eventImage]
  )

  const defaultImageSrc = useMemo(() => {
    if (!!url) {
      const category = categories.find((c) => c.urls.some((x) => x === url))
      if (!!category) {
        const urlIdx = category.urls.findIndex((x) => x === url)
        if (urlIdx > -1) {
          return category.images[urlIdx]
        }
      }
    }
    return null
  }, [url])

  return (
    <div className="flex flex-col w-[100%] relative sm:mb-0 mb-5">
      <Flex bg="white" overflow="hidden" direction="column" borderRadius="8px">
        <Image
          src={
            eventImageSrc ?? defaultImageSrc ?? (!!url ? url : DEFAULT_COVER)
          }
          w="100%"
          h="auto"
          onLoad={() => setLoading(false)}
        />
      </Flex>

      {!isLoading ? (
        <div
          className="absolute bottom-0 right-0 w-[40px] h-[40px] bg-primary flex items-center justify-center cursor-pointer rounded-br"
          onClick={() => setUploadDesignModal(true)}
        >
          <EditBorderIcon fill={'white'} />
        </div>
      ) : null}

      <PhotoOrVideoModal
        isOpen={uploadDesignModal}
        handleOnClose={() => setUploadDesignModal(false)}
        onSelectImageUrl={handleImageUrl}
        onSelectImageFiles={handleImageFile}
        removeDesignOnCanva
      />
    </div>
  )
}

export default NewEventImage

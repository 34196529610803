import { Input, InputGroup } from '@chakra-ui/react'
import React from 'react'
import { twMerge } from 'tailwind-merge'

const SquaresSquare = ({
  title,
  subtitle,
  placeholder,
  placeholderAfter,
  placeholderBefore,
  value,
  inputClassName,
  inputProps,
  wrapperInputClassName,
  removeOnChangeValidation,
  onChangeText,
  error
}) => {
  return (
    <div className="flex flex-col items-center">
      {title && <span className="text-[14px]">{title}</span>}
      <div
        className={twMerge(
          `w-[70px] h-[45px] rounded-md border-1 z-50 flex items-center justify-center relative ${error ? 'border-red-500 bg-red-100' : 'border-primary bg-off-white'}`,
          wrapperInputClassName
        )}
      >
        <div className="flex items-center justify-center relative">
          {placeholderBefore && placeholder && value && (
            <span className="text-[18px] text-primary ml-3">{placeholder}</span>
          )}
          <input
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={(e) => {
              let inputValue = e?.target?.value

              if (removeOnChangeValidation) {
                onChangeText(inputValue)
                return
              }

              const sanitizedValue = inputValue?.replace(/[^0-9$.]/g, '')

              onChangeText(sanitizedValue)
            }}
            style={{
              textIndent:
                placeholder && value && placeholderAfter
                  ? '20px'
                  : placeholder && value && placeholderBefore
                    ? '-10px'
                    : '0'
            }}
            className={twMerge(
              `w-full outline-none h-full min-w-0 text-center bg-transparent text-primary placeholder:text-[20px] ${error ? 'placeholder-red-500' : 'placeholder-primary'}`,
              inputClassName
            )}
            {...inputProps}
          />
          {placeholderAfter && placeholder && value && (
            <span className="text-[18px] text-primary mr-2">{placeholder}</span>
          )}
        </div>
      </div>
      {subtitle && <span className="mt-1">{subtitle}</span>}
    </div>
  )
}

export default SquaresSquare

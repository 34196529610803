import React, { memo, useCallback, useState, useMemo } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import EventDefaultImageVertical from '../assets/images/event_invited_vertical.png'
import { EditBorderIcon } from '../assets/icons'
import AttendingCard from './EventDetails/AttendingCard/AttendingCard'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ChangeResponseModal from './EventDetails/ChangeResponseModal/ChangeResponseModal'
import { update, set } from 'firebase/database'
import { ref, database } from '../firebase'
import moment from 'moment'
import { useToast, Flex, Image } from '@chakra-ui/react'
import { useObject } from 'react-firebase-hooks/database'
import { eventTimePassed } from '../helpers'

export const schema = yup
  .object({
    note: yup.string(),
    attending: yup.object().optional()
  })
  .required()

const RSVPEventDetailsView = ({
  eventInfo,
  isGuest,
  oldAnswer,
  removeAttendingCard = false
}) => {
  const { circleCode, rsvpId } = useParams()
  const [changeResponseModal, setChangeResponseModal] = useState(false)
  const navigate = useNavigate()
  const [updatingRSVP, setUpdatingRSVP] = useState(false)
  const isTimePassed = !!eventInfo?.isPremium && eventTimePassed(eventInfo)
  const toast = useToast()

  const [itemsSnap, itemsLoading] = useObject(
    ref(database, `circles/${circleCode || '1'}/items`)
  )

  const itemsInfo = itemsSnap?.val()

  const items = useMemo(
    () =>
      !!itemsInfo ? Object.keys(itemsInfo).map((key) => itemsInfo[key]) : [],
    [itemsInfo]
  )

  const hasRequestList = !!eventInfo?.extraCollab ?? false
  const hasMusic = !!eventInfo?.musicCollab ?? false

  const form = useForm({
    resolver: yupResolver(schema)
  })

  const toggleChangeResponseModal = useCallback(() => {
    const formattedAnswer = {
      value: oldAnswer === 'yes' ? 'yes' : 'no',
      label: oldAnswer === 'yes' ? "I'm Going 🎉" : 'Unable to attend 🙁'
    }
    form.setValue('attending', formattedAnswer)
    setChangeResponseModal(true)
  }, [form, oldAnswer])

  const handleCloseChangeResponseModal = useCallback(() => {
    setChangeResponseModal(false)
  }, [])

  const attendingOptions = useMemo(() => {
    return [
      {
        label: "I'm Going 🎉",
        value: 'yes'
      },
      {
        label: 'Unable to attend 🙁',
        value: 'no'
      }
    ]
  }, [])

  const handleUpdateRSVP = async () => {
    setUpdatingRSVP(true)

    const value = form.getValues('attending').value

    const response = value === 'no' ? 'no' : 'yes'

    // logEvent(analytics, 'invite_response', {
    //   source: response
    // })

    try {
      await update(ref(database, `circles/${circleCode}/guests/${rsvpId}`), {
        response: value
      })
      if (value === 'no') {
        const removingUserFromClaimedItems = items.reduce((acc, i) => {
          if (i.claimedBy === rsvpId) {
            const updatedItem = { ...i, claimed: false }

            delete updatedItem.claimedBy

            acc[i.id] = updatedItem
          } else {
            acc[i.id] = i
          }

          return acc
        }, {})

        await update(
          ref(database, `circles/${circleCode}/items`),
          removingUserFromClaimedItems
        )

        navigate(`/event/${circleCode}/rsvp/${rsvpId}/details`)
      } else {
        if (!!hasRequestList || !!hasMusic) {
          navigate(`/event/${circleCode}/rsvp/${rsvpId}/help`)
          return
        }
        navigate(`/event/${circleCode}/rsvp/${rsvpId}/details`)
      }
    } catch (e) {
      console.log('handleUpdateRSVP', e)
      toast({
        status: 'error',
        title: e.message || 'Error while sending response',
        position: 'top'
      })
    } finally {
      setUpdatingRSVP(false)
    }
  }

  const cover = !!eventInfo.url
    ? eventInfo.url
    : !!eventInfo.image
      ? eventInfo.image
      : EventDefaultImageVertical

  if (!eventInfo) {
    return null
  }

  return (
    <div className="flex flex-col w-[100%] sm:mb-0 mb-5 relative">
      <Flex bg="white" overflow="hidden" direction="column" borderRadius="8px">
        <Image src={cover} w="100%" h="auto" />
      </Flex>

      {isGuest && !removeAttendingCard && !isTimePassed && (
        <AttendingCard
          attending={oldAnswer === 'yes' ? true : false}
          onClick={toggleChangeResponseModal}
        />
      )}

      {!isGuest && !isTimePassed && (
        <div
          className="absolute bottom-0 right-0 w-[40px] h-[40px] bg-primary flex items-center justify-center cursor-pointer rounded-br"
          onClick={() => navigate(`/event/${circleCode}/edit`)}
        >
          <EditBorderIcon fill={'white'} />
        </div>
      )}

      <ChangeResponseModal
        isOpen={changeResponseModal}
        handleOnClose={handleCloseChangeResponseModal}
        form={form}
        attendingOptions={attendingOptions}
        handleUpdateRSVP={handleUpdateRSVP}
        loading={updatingRSVP}
      />
    </div>
  )
}

export default memo(RSVPEventDetailsView)

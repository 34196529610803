import moment from 'moment'
import React, { useMemo } from 'react'
import { CalendarIcon, ClockIcon } from '../../../assets/icons'
import NewEventDateAndTime from '../../NewEvent/NewEventDateAndTime/NewEventDateAndTime'

const DateTimeEditView = ({ eventInfo, form, isEditing, setEditing }) => {
  const timeOfEvent = useMemo(() => {
    if (!eventInfo || !eventInfo.date || !eventInfo.time || !eventInfo.timezone)
      return ''
    return moment(`${eventInfo.date}T${eventInfo.time}`).format(
      `hh:mm A [${eventInfo.timezone}]`
    )
  }, [eventInfo])

  return (
    <div className="flex flex-col item-center">
      {!!isEditing ? (
        <NewEventDateAndTime form={form} />
      ) : (
        <>
          <div className="bg-off-white p-3 flex flex-col justify-center mt-[12px] gap-3 rounded-md">
            <div className="flex flex-row items-center justify-start">
              <CalendarIcon />
              {eventInfo.date ? (
                <span className="ml-[8px]">
                  {moment(eventInfo.date).format('MMMM DD, YYYY')}
                </span>
              ) : (
                <span
                  className="ml-[8px] text-primary cursor-pointer font-semibold hover:underline"
                  onClick={() => setEditing(true)}
                >
                  Add date
                </span>
              )}
            </div>
            <div className="flex flex-row items-center justify-start">
              <ClockIcon />
              {eventInfo.time ? (
                <span className="ml-[8px]">{timeOfEvent}</span>
              ) : (
                <span
                  className="ml-[8px] text-primary cursor-pointer font-semibold hover:underline"
                  onClick={() => setEditing(true)}
                >
                  Add time
                </span>
              )}
            </div>
          </div>
          {!!eventInfo.date || !!eventInfo.time ? (
            <button
              className="text-primary cursor-pointer mt-1 py-2 font-bold hover:underline"
              onClick={() => setEditing(true)}
            >
              Edit Date/Time
            </button>
          ) : null}
        </>
      )}
    </div>
  )
}

export default DateTimeEditView

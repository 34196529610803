import { useToast } from '@chakra-ui/react'
import React from 'react'
import { ref, remove } from 'firebase/database'
import { database } from '../firebase'
import CancelModal from './CancelModal'

const DeleteWhatToBringListModal = ({ circleCode, isOpen, onClose }) => {
  const toast = useToast()

  const handleDelete = async () => {
    try {
      await Promise.all([
        remove(ref(database, `circles/${circleCode}/info/extraCollab`)),
        remove(ref(database, `circles/${circleCode}/items`))
      ])

      onClose()

      toast({
        title: 'List deleted',
        position: 'top',
        status: 'success',
        isClosable: true
      })
    } catch (err) {
      console.log('DeleteWhatToBringListModal: ', err)
      toast({
        title: 'Error deleting list',
        position: 'top',
        status: 'error',
        isClosable: true
      })
    }
  }

  return (
    <CancelModal
      title="Are you sure you want to delete this list?"
      label="What to bring list"
      isOpen={isOpen}
      onConfirm={handleDelete}
      onClose={onClose}
    />
  )
}

export default DeleteWhatToBringListModal
